.BoyPurchaseCard {
  min-width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 5px solid; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 1s;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 20px;
}

.BoyPurchaseCard p {
  text-align: center;
  font-size: 1.1rem;
}

.BoyPurchaseCard span {
  /* color: red; */
}

.BoyPurchaseCard img {
  height: 30%;
}

.BoyPurchaseCard_Address {
  margin-top: 20px !important;
  font-size: 0.6rem !important;
  color: green;
  font-weight: bold !important;
}

.WalletInfo {
  background: green;
  text-align: center;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0 auto;
}

.WalletInfo p {
  margin: 0;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.BoyPurchaseCard_Sep {
  height: 2px;
  padding-top: 1px;
  width: 100%;
  background: black;
}

.BoyPurchaseCard_Separator {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 5px;
  width: 100%;
}

.BoyPurchaseCard_ActionText {
  color: black;
  font-weight: bold;
}

.BoyPurchaseCard_ActionButton {
  background: white;
  border: 1px solid black;
  color: black;
  font-family: "Montserrat";
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  transition: 1s;
  cursor: pointer;
  margin-bottom: 20px;
  min-width: 150px;
  min-height: 50px;
}

@keyframes anim-glow {
  0% {
    filter: drop-shadow(0 0 2rem yellow);
  }
  50% {
    filter: drop-shadow(0 0 0rem yellow);
  }
  100% {
    filter: drop-shadow(0 0 2rem yellow);
  }
}

.react-numeric-input input {
  padding-right: 6ex !important;
  padding-left: 6ex !important;
  min-height: 50px;
  font-family: "Montserrat";
  color: black;
  background: transparent;
  outline: none;
  border: 1px solid black;
}

.react-numeric-input b {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background: transparent !important;
}

.react-numeric-input b:hover {
  cursor: pointer !important;
}

.react-numeric-input b:nth-child(3) {
  border-right: 1px solid black !important;
}

.react-numeric-input b:nth-child(2) {
  border-left: 1px solid black !important;
}

.react-numeric-input b i {
  font-family: "Montserrat" !important;
  background: black !important;
}

.Modal_Body {
  font-family: "Montserrat" !important;
}

.skylight-dialog {
  background: black !important;
  color: white !important;
}

.separator {
  height: 0.5px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
}

.Modal_Title {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.Modal_Title p {
  margin-left: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.skylight-close-button {
  top: 10px !important;
  right: 20px !important;
}

.Modal_Entries {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Modal_Entry {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
}

.Modal_Entry a {
  color: white;
}

.Modal_Actions {
  display: flex;
  justify-content: center;
}

.Modal_Actions button {
  background: black;
  border: 1px solid white;
  color: white;
  font-family: "Montserrat";
  font-size: 1.4rem;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  transition: 1s;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.Modal_Confirmation {
  background: greenyellow;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  animation: flash 2.5s ease-in infinite;
  color: black;
}

.Modal_Status {
  text-align: left !important;
}

.Modal_Status span {
  color: white;
  font-weight: bold;
  font-size: 0.9rem !important;
}

@media only screen and (max-width: 600px) {
  .BoyPurchaseCard {
    min-width: 100%;
  }

  .Modal_Status {
    font-size: 0.8rem !important;
  }

  .skylight-close-button {
    font-size: 1.4rem !important;
  }
  .Modal_Status span {
    color: yellow;
    font-size: 0.8rem !important;
  }
  .Modal_Entries {
    flex-direction: column;
    justify-content: center;
  }
  .Modal_Entry {
    flex-direction: column;
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
  }
  .Modal_Entry p {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    font-size: 0.3rem;
    margin-bottom: 5px;
  }
  .Modal_Actions {
    flex-direction: column;
  }
}

@media screen and (orientation: landscape) and (max-width: 840px) {
  .BoyPurchaseCard {
    width: 80%;
  }
  .Modal_Status {
    font-size: 0.8rem !important;
  }

  .skylight-close-button {
    font-size: 1.4rem !important;
  }
  .Modal_Status span {
    color: yellow;
    font-size: 0.8rem !important;
  }
  .Modal_Entries {
    flex-direction: column;
    justify-content: center;
  }
  .Modal_Entry {
    flex-direction: column;
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
  }
  .Modal_Entry p {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .Modal_Actions {
    flex-direction: column;
  }
}
/* 
@keyframes flash {
  0% {
    background: rgb(0, 150, 0);
  }
  50% {
    background: rgb(0, 195, 0);
  }
  100% {
    background: rgb(0, 150, 0);
  }
} */

.PassText {
  color: white;
  max-width: 85%;
}

.PassInput {
  width: 50%;
  height: 35px;
  font-family: "Press Start 2P";
}
