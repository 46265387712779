.Header_Container {
  width: 100%;
}

.Header {
  position: absolute;
  top: 40px;
  min-height: 75px;
  display: flex;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  justify-content: center;
}

.rarity {
  background-color: rgb(190, 24, 93);
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 1px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: white;
  border-radius: 0.5rem;
  font-size: 1.2rem;
}

.Header_Logo {
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
}
.mobile-menu {
  display: none;
}

.Header_Nav {
  display: flex;
  align-items: center;
}

.Header_Nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Header_Nav ul li {
  float: left;
}

.Header_Nav ul li a {
  display: block;
  color: white !important;
  text-align: center;
  padding: 16px;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 300;
  transition: 0.4s;
  cursor: pointer;
}

.Header_Nav ul li a:hover {
  opacity: 0.5;
}

.Header_Socials {
  margin-left: 60px;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.Header_Socials img {
  padding-left: 8px;
  padding-right: 8px;
  max-width: 35px;
  transition: 0.5s;
}

.Header_Socials img:hover {
  opacity: 0.5;
  cursor: pointer;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: grey;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0px;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  outline: none;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-top: 20px;
  cursor: pointer;
  outline: none;
  color: black !important;
  text-decoration: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0px;
}
