.SocialsSection {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  font-size: 0.8rem;
}

.SocialsSection_Icons {
  margin-top: 10px;
  margin-bottom: 10px;
}

.SocialsSection_Icons img {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.SocialsSection_Icons img:hover {
  opacity: 0.75;
}

.SocialsSection a {
  color: white;

  text-decoration: none;
  text-decoration: underline;
}

.SocialsSection_Rights {
  color: white;
  margin: 0;
}

.SocialsSection_Contract {
  color: white;
}

.SocialsSection a:hover {
  color: white;
  opacity: 0.85;
}

.SocialsSection_LB {
  text-decoration: none !important;
}
